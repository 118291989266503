@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eae7c6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.todos {
  border-radius: 10px;
  background-color: #c37b89;
}
.todos.done {
  border-radius: 10px;
  background-color: #bccc9a;
}

